// 
// dropdowns.scss
// Extended from Bootstrap
// 

// 
// Bootstrap Overrides
//

.dropdown{
  outline: none;
  box-shadow: none;
}

// Dropdown soft hover
.dropdown-item.active, .dropdown-item:active,
.dropdown-item:hover, .dropdown-item:focus{
  border-radius: $border-radius;
}
.dropdown-menu{
  padding-left: 10px;
  padding-right: 10px;
  z-index: 1100;
  box-shadow: $box-shadow-sm;
  border: 1px solid var(--#{$prefix}border-color);
}

.dropdown-item{
  margin-bottom: 0.3rem;
}

// Dropdown custom icon
.dropdown .dropdown-toggle:after {
  content: "\f107";
  font-family: $fa-font-family;
  font-weight: 900;
  vertical-align: middle;
  border: none;
}

// Dropup custom icon
.dropup .dropdown-toggle:after {
  content: "\f106";
  font-family: $fa-font-family;
  font-weight: 900;
  vertical-align: middle;
  border: none;
}

// Dropstart custom icon
.dropstart .dropdown-toggle:before {
  content: "\f104";
  font-family: $fa-font-family;
  font-weight: 900;
  vertical-align: middle;
  border: none;
}

// Dropend custom icon
.dropend .dropdown-toggle:after {
  content: "\f105";
  font-family: $fa-font-family;
  font-weight: 900;
  vertical-align: middle;
  border: none;
}

// Dropdown fullwidth mega menu
.dropdown-fullwidth {
  position: static;
  .dropdown-menu {
    margin: 0 auto;
    left: 0;
    right: 0;
    padding: 1rem 0;
    top: auto;
  }
}

// Dropdown fullwidth menu width on responsive
@each $breakpoint, $container-max-width in $container-max-widths {
  @include media-breakpoint-up($breakpoint) {
    .dropdown-fullwidth {
      .dropdown-menu {
        max-width: $container-max-width;
      }
    }
  }
}


// Dropdown menu slide in animation on click
.dropdown-animation .dropdown-menu.show {
  animation-duration: 0.3s !important;
  animation-fill-mode: both;
  animation-name: dropdownslideIn;
  position: absolute !important;
  top: 100% !important;
  transition: none;
}

@keyframes dropdownslideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform:translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes dropdownslideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}
