// Fonts
//@import url('https://fonts.bunny.net/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

@import 'app/style';

@import '../css/app.css';
@import '../css/color.css';
@import '../css/style.css';
@import '../css/style-rtl.css';
