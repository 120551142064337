
:root{
    --green : #09B850;
    --sageGreen : #0aa073
}
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.main-logo {
    width: 150px;
    height: 40px;
    border-radius: 20px
}

.link-color{
    color: var(--green);
}
.link-color:hover{
    color: var(--sageGreen)
}
.common-bg{
    border-color: var(--green);
    background-color: var(--green);
}

.common-bg:hover{
    border-color: var(--sageGreen);
    background-color: var(--sageGreen);
    cursor: pointer;
}
.join-button {
    font-family: "Inter", sans-serif;
    font-size: 20px;
    width: 150px;
    height: 50px;
    border-radius: 20px;
    border-color: var(--green);
    background-color: var(--green);
    color: white;
}
.join-button:hover{
    background-color: var(--sageGreen);
    border-color: var(--sageGreen);
    color:white;
}
.join-button-bottom{
    background-color: #25D366;
    border-color: #25D366;
    color: white;
}
.join-button-bottom:hover{
    background-color: #0aa073;
    border-color: #0aa073;
    transition-duration: 0.5s;
}

.main-container{
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

.main-navbar {
    z-index: 10;
    width: 100%;
    position: fixed;
    background-color: white;
}

.responsive-dropdown-open {
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition-duration: 0.5s;
}

.demos-dropdown-close {
    display: none;
}

.demos-link {
    text-decoration: none;
    display: block;
    margin: 0 1rem;

    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    background: none;
    border: 0;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.demos-link:hover {
    color: #25D366;
    cursor: pointer;
}

.demos-dropdown-open {
    display: flex;
    flex-direction: column;
}

.flex {
    display: flex;
}

.pages-dropdown-close {
    display: none;
}

.pages-dropdown-open {
    display: flex;
    flex-direction: column;
}

.dashboard-main-section {
    overflow: hidden;
    padding-top: 8rem;
    margin-bottom: -3.5rem;
}

a.button {
    width: 150px;
    text-align: center;
    height: 50px;
    padding: 10px;
    border-radius: 10px;
    text-decoration: none;
    background-color: #25D366;
}

.arrow {
    background-color: #25D366;
    color: #f6f3ed;

    &::after {
        display: inline-block;
        padding-left: 8px;
        content: "\0279E";
        -webkit-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -ms-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
    }

    &:hover {
        &::after {

            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
        }
    }
}


.dashboard-container {
    --bs-gutter-x: 1.875rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
    position: relative;
    z-index: 5;
    padding-top: 2.5rem
}

.contact {
    margin-left: 1rem;
    margin-right : 1rem;
    width: 150px;
    height: 50px;
    font-size: 20px;
}

.contact-arrow {
    color: black;
    border-color: transparent;
    background-color: transparent;

    &::after {
        display: inline-block;
        padding-left: 8px;
        content: "\0279E";
        -webkit-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -ms-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
    }

    &:hover {
        border: none;
        background-color: transparent;
        border-color: transparent;
        color: black;

        &::after {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
        }
    }
}

.team-image {
    width: 80%;
    height: 80%;
}

.footer-elements {
    padding-top: 3rem;
    margin-top: 3rem;
    color: white;
}

.follow-links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social-icons {
    width: 20px;
    height: 20px;
}

.contact-links {
    color: white;
}

.polygon-border {
    fill: grey;
}

.polygon {
    fill: #25D366;
}

.email-header {
    text-align: start;
    color: white;
}

.footer {
    margin: 40px 40px 40px 50px;
}

.newsletter-container {
    display: flex;
    flex-direction: column;
}

.contact-text div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contact-text div p {
    margin-top: 0.7rem;
    margin-left: 1rem;
}

.grey-button {
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    height: 3rem;
    width: auto;
    margin: 0.5rem;
    --bs-btn-color: #fff;
    --bs-btn-bg: #85878a;
    --bs-btn-border-color: #85878a;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #717375;
    --bs-btn-hover-border-color: #6a6c6e;
    --bs-btn-focus-shadow-rgb: 151, 153, 156;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #6a6c6e;
    --bs-btn-active-border-color: #646568;
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #85878a;
    --bs-btn-disabled-border-color: #85878a;
}


.our-mission-arrow{
    font-size: large;
    color: #25D366;
    border:none;
    background-color: transparent;

        &::after {
            display: inline-block;
            padding-left: 8px;
            content: "\0279E";
            -webkit-transition: transform 0.3s ease-out;
            -moz-transition: transform 0.3s ease-out;
            -ms-transition: transform 0.3s ease-out;
            -o-transition: transform 0.3s ease-out;
            transition: transform 0.3s ease-out;
        }

        &:hover {
            border: none;
            background-color: transparent;
            color: #0a966c;

            &::after {
                -webkit-transform: translateX(4px);
                -moz-transform: translateX(4px);
                -ms-transform: translateX(4px);
                -o-transform: translateX(4px);
                transform: translateX(4px);
            }
        }

}
.plans-container {
    margin-top: 3rem;
    display : flex;
    flex-direction: column;
}
.plan{
    display: flex;
    flex-direction: column;
    margin: 2rem;
    width:200px;
    padding: 1rem;
    height: 200px;
    border-width: 0.2rem;
    border-color : #0cbc87;
    border-style: solid;
    border-radius: 1rem;
}

.early-backer{
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    width: 40%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1e93a1;
    color: white;
    border-radius: 1rem;
}
.early-backer h4{
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}
.early-backer div{
    margin-bottom: 1rem;
}
.early-backer-button{
    border-style: solid;
    border-width: 0.2rem;
    border-color: #fdb915;
    border-radius: 1rem;
    color : #fdb915;
    background-color: transparent;
}
.early-backer-button:hover{
    background-color: whitesmoke;
    color: #1e93a1;
}
.testimonials-container{
    text-align: left;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-self: start;
    align-items: flex-start;
    justify-content: flex-start;
}

.grid-container{
    display: grid;
    grid-template-columns: 45% 55%;
    justify-content: center;
    padding: 2rem;
}
.customer-image{
    align-self: center;
    margin-left: 1rem;
    margin-right: 1rem;
}
.mission-arrow{
    font-size: 1.5rem;
    color: #0a966c;
    background-color: transparent;
    border: none;
    width: 70%;
    &::after {
        display: inline-block;
        background-color: transparent;
        border:none;
        padding-left: 8px;
        content: "\0279E";
        -webkit-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -ms-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
    }

    &:hover {
        border: none;
        color: #0a966c;
        background-color: transparent;
        cursor: pointer;
        &::after {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
        }
    }
}
.testimonials{
    display: flex;
    margin-left: 0;
    flex-direction: column;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
}
.testimonials div{
    margin-left: 0;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
}
.testimonials p{
    margin-left: 0;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
}
.policy-container{
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    justify-content: center;
}
.privacy-policy{
    padding: 2rem;
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

}
.button-container{
    display: flex;
    flex-direction: row;
}
.social-buttons{
    margin: 0 0.5rem;
    border-radius: 0.5rem;
    width: 150px;
    height: 40px;
}
.social-buttons:hover{
    cursor: pointer;
}

#formInput:focus{
    color: #202124;
    background-color: #fff;
    border-color: #20212426;
    outline: 0;
    box-shadow: 0 4px 10px 3px #20212426;
}

.form-input:focus{
    color: #202124;
    background-color: #fff;
    border-color: #20212426;
    outline: 0;
    box-shadow: 0 4px 10px 3px #20212426;
}
.group-image{
    width: 60%;
    height: 100%
}
.testimonials-image{
    width: 100%;
    height: 100%;
}

.bg-color {
    background-color: #d7fae7
}

.faq-button:hover{
    cursor: pointer;
}

.faq-button:not(.collapsed)::after {
    color: white;
    background-image: none
}

.faq-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: black;
    text-align: left;
    background-color: white;
    border-width :0.031rem;
    border-color: #c5c0c0;
    border-radius: 5px;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
    .faq-button {
        transition: none;
    }
}
.dark-theme{
    background-color: black;
    color: white;
}
.faq-button:not(.collapsed) {
    color: white;
    background-color: black;
    border : none;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.faq-button:not(.collapsed)::after {
    color: white;
    border : none;
    background-color: black;
}
.faq-button::after {
    flex-shrink: 0;
    width: 0;
    height: 0;
    margin-left: auto;
    content: "";
    transition: var(--bs-accordion-btn-icon-transition);
}

@media (min-width: 480px) {
    .team-image {
        border-radius: 1rem;
        width: 80%;
        height: 80%;
    }

    .main-buttons {
        margin-left: auto;
        margin-right: auto;
    }

    .nav-list {
        margin-top: 0.625rem;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .nav-list li {
        margin: 5px 0;
        padding: 2px 0;
    }

    .nav-list li:hover {
        background-color: #25D366;
        color: white;
    }

    .nav-list li a:hover {
        color: white;
    }

    .main-navbar {
        position: relative;
    }

    .email-modal-container {
        background-color: #25D366;
        margin: 6rem auto -2rem;
        z-index: 3;
        border-radius: 0.5rem;
    }

    .contact-grid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }

    .contact-text {
        color: white;
        align-items: center;
        position: absolute;
    }

    .communities {
        margin-top: 3rem;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .details-container{
        width : 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .details {
        background-color: transparent;
        margin-top: 1rem;
        width: 60%;
        height: auto;
        border-width: 0 0 0.1rem 0;
        border-style: solid;
        border-color: grey;
    }
    .community-buttons{
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .plans-container {
        margin-top: 3rem;
        display : flex;
        flex-direction: column;
    }
    .plan{
        display: flex;
        flex-direction: column;
        margin: 2rem;
        width:200px;
        padding: 1rem;
        height: 200px;
        border-width: 0.2rem;
        border-color : #0cbc87;
        border-style: solid;
        border-radius: 1rem;
    }

    .mission-container{
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        align-items: center;
        width: 80%;
        background-color: #efeeee;
    }

    .mission-container div{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .join-actionize{
        background-color: var(--green);
        border-color: var(--green);
        font-size: large;
        color: white;
        width: 80%;
        height: 4rem;
        padding: 1rem;
        margin: 2rem;
        border-radius: 1rem;
    }
    .join-actionize:hover{
        background-color: var(--sageGreen);

    }
    .discover-mission-button{
        font-size: larger;
        color : var(--green);
    }
    .discover-mission-button:hover{
        color: var(--sageGreen)
    }
    .grid-container{
        text-align: center;
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
    }
    .testimonials{
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        justify-content: center;
    }
    .testimonials div{
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        width: 100%;
        height: 100%;
    }
    .testimonials div p{
        margin-left: auto;
        margin-right: auto;
    }
    .button-container{
        display: flex;
        flex-direction: column;
    }
    .social-buttons{
        border-radius: 0.5rem;
        margin: 0.5rem;
        width: 150px;
        height: 40px;
    }
    .group-image{
        width: 80%;
        height: 100%
    }
}

@media (min-width: 1200px) {
    .team-image {
        width: 50%;
        height: 50%;
        border-radius: 1rem;
    }

    .responsive-dropdown-open {
        display: none;
    }

    .email-modal {
        display: grid;
        padding: 0.5rem 0.5rem 0;
        grid-template-columns: 45% 55%;
        border-radius: 0.5rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        background-color: #25D366;
    }

    .email-modal-container {
        padding: 0.5rem 0.5rem 0;
        z-index: 3;
        width: 80%;
        margin: 6rem auto -2rem;
    }

    .contact-grid {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .contact-text {
        position: relative;
        color: white;
    }

    .communities div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .details-container{
        width : 100%;
        height: max-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .details {
        background-color: white;
        width: 30%;
        height: 100%;
        border: none
    }
    .plans-container {
        margin-top: 3rem;
        display : flex;
        flex-direction: column;
    }
    .plan{
        display: flex;
        flex-direction: column;
        margin: 2rem;
        width:200px;
        padding: 1rem;
        height: 200px;
        border-width: 0.2rem;
        border-color : #0cbc87;
        border-style: solid;
        border-radius: 1rem;
    }
    .grid-container{
        display: grid;
        grid-template-columns: 45% 55%;
        justify-content: center;
        padding: 2rem;
    }
    .testimonials{
        display: flex;
        margin-left: 0;
        flex-direction: column;
        align-self: flex-start;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .testimonials div{
        margin-left: 0;
        text-align: left;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .testimonials p{
        margin-left: 0;
        text-align: left;
        align-items: flex-start;
        justify-content: flex-start;
    }
}
